import request from '@/utils/request.js';
/**
 * 经费列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/envfundLedger/getRecordPage',
    method: 'post',
    data
  });
}
/* 删除经费记录
 * @returns {Promise}
 */
export function deleteRecord(data) {
  return request({
    url: '/traffic-construction/envfundLedger/deleteRecord',
    method: 'post',
    data
  });
}
/* 新增或修改
 * @returns {Promise}
 */
export function addOrEditRecord(data) {
    return request({
      url: '/traffic-construction/envfundLedger/addOrEditRecord',
      method: 'post',
      data
    });
  }
  /* 检查是否已存在记录
 * @returns {Promise}
 */
export function checkPlanOrUse(data) {
  return request({
    url: '/traffic-construction/envfundLedger/checkPlanOrUse',
    method: 'post',
    data
  });
}
  /* 导出记录
 * @returns {Promise}
 */
  export function exportRecord(data) {
    return request({
      url: '/traffic-construction/envfundLedger/exportRecord',
      method: 'post',
      data,
      responseType: 'blob'
    });
  }