export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择计划年份'));
      }
      callback();
    };
    return {
      rules: {
        projectDepartment: [{ validator: validateOrgId, trigger: '' }],
        year: [{ validator: validatePost, trigger: '' }],
      }
    };
  }
};
